<template>
  <div id="nav" class="flex flex-col justify-between h-screen min-h-screen">
    <div class="h-auto">
      <header-logos></header-logos>
      <header-warranties v-show="$route.path!=='/'"></header-warranties>
      <router-view></router-view>
    </div>
    <footer-warranty></footer-warranty>
  </div>
</template>

<script>
import FooterWarranty from '@/components/FooterWarranty.vue';
import HeaderLogos from '@/components/HeaderLogos.vue';
import HeaderWarranties from '@/components/HeaderWarranties.vue';

export default {
  components: {
    HeaderLogos,
    HeaderWarranties,
    FooterWarranty,
  },
};
</script>

<style>
#app {
  font-family: Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
