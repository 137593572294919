import { createStore } from 'vuex';
import axios from 'axios';
import countriesList from '@/config/countries-list';

export default createStore({
  state: {
    harp: {},
    dealers: [],
    locale: 'en',
    countries: countriesList,
    customer: {},
    warranty: {},
  },
  getters: {
    harp(state) {
      return state.harp;
    },
    dealers(state) {
      return state.dealers;
    },
    locale(state) {
      return state.locale;
    },
    countries(state) {
      return state.countries[state.locale];
    },
    customer(state) {
      return state.customer;
    },
    warranty(state) {
      return state.warranty;
    },
  },
  mutations: {
    INIT(state) {
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_AITEMS_URL}/warranty/dealers`,
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${process.env.VUE_APP_AITEMS_TOKEN}`,
        },
      }).then(({ data }) => {
        state.dealers = data;
      });
    },
    ADD_HARP(state, harp) {
      state.harp = harp;
    },
    CHANGE_LOCALE(state, locale) {
      state.locale = locale;
    },
    ADD_CUSTOMER(state, customer) {
      state.customer = customer;
    },
    ADD_WARRANTY(state, warranty) {
      state.warranty = warranty;
    },
  },
  actions: {
    initialize(context) {
      context.commit('INIT');
    },
    addHarp(context, harp) {
      context.commit('ADD_HARP', harp);
    },
    changeLocale(context, locale) {
      context.commit('CHANGE_LOCALE', locale);
    },
    addCustomer(context, customer) {
      context.commit('ADD_CUSTOMER', customer);
    },
    addWarranty(context, warranty) {
      context.commit('ADD_WARRANTY', warranty);
    },
  },
  modules: {},
});
