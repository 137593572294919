import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import en from '@/locales/en';
import it from '@/locales/it';
import { getBrowserLocale } from '@/util/i18n/get-browser-locale';
import { supportedLocalesInclude } from '@/util/i18n/supported-locales';
import LitepieDatepicker from 'litepie-datepicker';
import dayjs from 'dayjs';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';

/* function getLocaleMessages() {
  const messages = {};

  Object.keys(supportedLocales).forEach((locale) => {
    import(`@/locales/${locale}`).then((loadedMessage) =>
    { messages[locale] = loadedMessage.default; });
  });

  return messages;
} */
const customParseFormat = require('dayjs/plugin/customParseFormat');
const utc = require('dayjs/plugin/utc');

dayjs.extend(customParseFormat);
dayjs.extend(utc);

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  }

  return process.env.VUE_APP_I18N_LOCALE || 'en';
}

/* eslint-disable */
const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: getStartingLocale(),
  messages: {
    en: en,
    it: it,
  }
});

createApp(App).use(store).use(router).use(i18n).use(LitepieDatepicker).use(dayjs).mount('#app');

store.dispatch('initialize');
