export default
{
  language: {
    choose: 'Scegli la lingua:',
  },
  header: {
    dear: 'Gentile Arpista,',
    thanks: 'Grazie per aver acquistato un’arpa Salvi! La vostra nuova arpa è coperta da una garanzia spettante per legge. La Salvi offre gratuitamente una estensione convenzionale di questa garanzia portandola a 3 anni sulle arpe celtiche e 5 anni sulle arpe a pedali.',
    important: 'ATTENZIONE: Per attivare l’estensione convenzionale della garanzia è necessario registrare su questa pagina l’acquisto della vostra nuova arpa, entro 30 giorni dalla data di acquisto. A conferma della registrazione riceverete un messaggio di posta elettronica, che vi preghiamo voler conservare insieme alla fattura relativa all’acquisto.',
    warranty: 'Garanzia',
    registration: 'REGISTRAZIONE DELLA VOSTRA NUOVA ARPA SALVI',
    readTerms: 'Clicca qui per leggere le condizioni di garanzia',
  },
  serial: {
    number: 'Inserisci il numero di serie',
    confirm: 'Conferma',
    find: 'Dove trovo il numero di serie?',
    where: 'Accanto alla parte superiore della colonna (targhetta di ottone o etichetta di carta) - Sotto la base dell’arpa.',
    family: 'Scegli la famiglia',
    bad_P: 'Controlla il numero di serie. Per le arpe a pedali inizia con P1 o P2 seguito da 4 cifre: P1****  P2****',
    not_found_P: 'Non riusciamo a trovare l\'arpa sui nostri sistemi :( Controlla il numero di serie. Per le arpe a pedali inizia con P1 o P2 seguito da 4 cifre: P1****  P2****. Se il problema persiste mandaci una email a ',
    bad_L: 'Controlla il numero di serie. Per le arpe a levette inizia con L3 o L4 seguito da 4 cifre: L3****  L4****',
    not_found_L: 'Non riusciamo a trovare l\'arpa sui nostri sistemi :( Controlla il numero di serie. Per le arpe a levette inizia con L3 o L4 seguito da 4 cifre: L3****  L4****. Se il problema persiste mandaci una email a ',
    bad_DH: 'Controlla il numero di serie. Per la DELTA inizia con DH seguito da 4 cifre: DH****',
    not_found_DH: 'Non riusciamo a trovare l\'arpa sui nostri sistemi :( Controlla il numero di serie. Per la DELTA inizia con DH seguito da 4 cifre: DH****. Se il problema persiste mandaci una email a ',
  },
  registration: {
    harp: 'arpa',
    finish: 'finitura',
    serial: 'numero di serie',
    date: 'data di acquisto',
    dealer: 'seleziona il tuo rivenditore',
    customer: 'Acquirente',
    firstname: 'Nome',
    lastname: 'Cognome',
    address: 'Indirizzo',
    city: 'Città',
    postcode: 'CAP',
    province: 'Provincia',
    nations: 'Nazione',
    phone: 'Telefono',
    mail: 'Indirizzo mail',
    other: 'ALTRO',
    other_message: 'Specificare altro rivenditore',
    terms: 'Fai <a class="font-bold" href="/pdf/warranty-conditions.pdf" target="_blank">click qui</a> per leggere e accettare i termini e condizioni della Garanzia Convenzionale',
    privacy: 'Do il consenso al trattamento dei dati personali. Per dettagli inerenti alla legge sulla privacy <a class="font-bold" href="/pdf/Salvi_PrivacyPolicy-IT.pdf" target="_blank">clicca qui</a>.',
    tax: 'Codice Fiscale/ P. IVA',
    activation: 'La garanzia è stata attivata con successo!',
    errors: 'Oh mannaggia! Qualcosa non funziona nelle comunicazioni tra i server! Riprova ... se i server fanno i pigri e non rispondono manda una email.',
    'confirmation-mail': 'Riceverai una mail di conferma al tuo indirizzo.',
    'purchased-from': 'Acquistata da:',
    'customer-details': 'Il tuo indirizzo:',
    'sale-date': 'Data di acquisto',
    'end-warranty': 'Data fine garanzia',
  },
  newsletter: {
    mail: 'Inserisci la mail qui..',
    subscribe: 'Iscriviti alla newsletter di Salvi Harps per ricevere interessanti news e offerte!',
  },
  input: {
    fillField: 'Compila questo campo',
  },
};
