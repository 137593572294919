<template>
    <div class="py-4 border-t border-beige-200 mx-6 mt-10 text-xs leading-5">
        <p>
            N.S.M. Spa - Via Rossana 7 - 12026 Piasco CN - Italy
        </p>
        <p>
            Tel: +39 0175 270 511 • Fax: +39 0175 270 512 <br>
            <a href="mailto:info@salviharps.com"
                class="font-bold text-beige-300">
                info@salviharps.com </a> •
            <a href="https://www.salviharps.com/" class="font-bold text-beige-300">
                www.salviharps.com </a>
        </p>
        <div class="flex text-xs w-full items-center justify-center
            text-beige-150 mt-4 h-auto">
            <div class="flex flex-row items-center h-auto">
                <h4 class="text-xs md:px-2">Warranty is another fine product of <a
                    href="https://www.thenonsensefactory.it" target="blank">The Nonsense Factory</a></h4>
                <h4 class="text-xs pl-2">{{new Date().getFullYear()}} © Salvi Harps</h4>
            </div>
        </div>
    </div>
</template>
