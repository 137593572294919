import supportedLocales from '@/config/supported-locales';

export function getSupportedLocales() {
  const annotatedLocales = [];

  Object.keys(supportedLocales).forEach((key) => {
    annotatedLocales.push({
      code: key,
      name: supportedLocales[key],
    });
  });

  return annotatedLocales;
}

export function supportedLocalesInclude(locale) {
  return Object.keys(supportedLocales).includes(locale);
}
