<template>
    <div class="w-full shadow-2xl py-4 lg:py-6 bg-beige-100 text-xs lg:text-sm">
        <div class="w-4/5 lg:w-1/2 mx-auto leading-4 lg:leading-6">
            <p class="pb-1">
                {{$t('header.dear')}}<br>
                {{$t('header.thanks')}}
            </p>
            <br>
            <p>
                {{$t('header.important')}}
            </p>
            <a href="/pdf/warranty-conditions.pdf" target="_blank" class="font-bold">
                {{$t('header.readTerms')}}
            </a>
        </div>
    </div>
</template>
