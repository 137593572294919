<template>
    <div class="flex flex-col lg:flex-row bg-beige-100 border-b-2 border-beige-200
        items-center h-auto lg:h-32 py-6 lg:relative">
        <div class="flex lg:absolute lg:inset-x-0 lg:left-0 lg:ml-32 ml-0">
            <img src="../images/logo-salvi-harps.png" alt="Salvi Harps Logo"
            class="w-32 lg:w-40 h-full">
        </div>
        <div class="flex flex-col lg:absolute lg:inset-x-0 mt-4 lg:mt-0 w-full">
            <h1 class="text-xl lg:text-2xl uppercase font-semibold">{{$t('header.warranty')}}</h1>
            <p class="pt-3 text-md lg:text-lg uppercase">
                {{$t('header.registration')}}
            </p>
        </div>
        <div class="flex text-beige-200 mt-4 lg:mt-0 lg:absolute lg:right-0 lg:mr-32 mr-0">
            <button>
                <a href="https://it-it.facebook.com/SalviHarps/">
                    <facebook-logo class="w-8 lg:w-10 pr-3"/>
                </a>
            </button>
            <button>
                <a href="https://www.instagram.com/salviharpsofficial/?hl=it">
                    <instagram-logo class="w-6 lg:w-8 rounded-md"/>
                </a>
            </button>
        </div>
    </div>
</template>

<script>
import facebookLogo from '../svg/facebookLogo.vue';
import instagramLogo from '../svg/instagramLogo.vue';

export default {
  components: {
    facebookLogo,
    instagramLogo,
  },
};
</script>
