import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '',
        name: 'landing',
        component: () => import('@/components/chooseLanguage.vue'),
      },
      {
        path: '/harp',
        name: 'Serial Number',
        component: () => import('@/components/SerialNumber.vue'),
      },
      {
        path: '/customer-form',
        name: 'Customer Data',
        component: () => import('../views/RegistrationForm.vue'),
      },
      {
        path: '/summary',
        name: 'Warranty Summary',
        component: () => import('../views/WarrantySummary.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
